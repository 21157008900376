import { ClinicSearchResult } from '@/api/algolia';
import { makeSearchTitle } from './search';

export const convertSearchResponseIntoListingInfo = (
  searchHealthcareService?: string,
  searchLocation?: string,
  searchResponse?: ClinicSearchResult,
) => {
  if (!searchResponse) return {};
  return {
    title: makeSearchTitle(searchHealthcareService, searchLocation),
    results: searchResponse?.hits ?? [],
    total: searchResponse?.nbHits,
    pages: searchResponse?.nbPages,
  };
};
