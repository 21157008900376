import { GetReservationBySecretQueryResult, PaymentGateway as PaymentGatewayCore, PaymentStatus as PaymentStatusCore } from '@/types-core';
import { DEFAULT_DOC_ALIAS } from '@/costants';
import {
  AggregatorPaymentGateway,
  AggregatorPaymentStatus,
  ReservationBySecretV2QueryResult,
  ReservationOrigin,
  SpecialityByIdQueryResult,
} from '@/types-aggregatore';
import { capitalizeWordsInString } from '@/utils/string';

export const formatReservationPrice = (standardPrice?: number) => {
  return `${(standardPrice ?? 0) / 100}`;
};

export const formatDoctorName = (speciality?: SpecialityByIdQueryResult['specialityById']) => {
  const doctor = speciality?.doctors?.[0];
  const name = `${speciality?.doctors?.[0]?.name} ${speciality?.doctors?.[0]?.surname}`;

  return doctor?.isNameVisible && name !== DEFAULT_DOC_ALIAS ? `Dott. ${capitalizeWordsInString(name)}` : DEFAULT_DOC_ALIAS;
};

export const getPaymentStatusFromReservation = (reservation?: ReservationBySecretV2QueryResult['reservationBySecretV2']) => {
  const { paymentTransaction } = reservation ?? {};
  if (
    (paymentTransaction?.paymentGateway === AggregatorPaymentGateway.Tantosvago ||
      paymentTransaction?.paymentGateway === AggregatorPaymentGateway.Welbee) &&
    paymentTransaction?.status === AggregatorPaymentStatus.Paid
  ) {
    return 'Pagato in crediti welfare';
  }

  if (
    (paymentTransaction?.paymentGateway === AggregatorPaymentGateway.Davinci ||
      paymentTransaction?.paymentGateway === AggregatorPaymentGateway.Stripe) &&
    paymentTransaction?.status === AggregatorPaymentStatus.Paid
  ) {
    return 'Pagamento online';
  }

  if (
    (paymentTransaction?.origin === ReservationOrigin.Sisalute || paymentTransaction?.origin === ReservationOrigin.Argento) &&
    paymentTransaction?.status === AggregatorPaymentStatus.Paid
  ) {
    return 'Pagato usando voucher';
  }

  return 'Pagamento non ancora effettuato';
};

export const getPaymentStatusFromReservationCore = (reservation: GetReservationBySecretQueryResult['getReservationBySecret']) => {
  const { paymentTransactions } = reservation ?? {};
  const paymentTransactionStripe = paymentTransactions?.find(
    paymentTransaction => paymentTransaction?.paymentGateway === PaymentGatewayCore.Stripe,
  );
  const paymentTransactionDavinci = paymentTransactions?.find(
    paymentTransaction => paymentTransaction?.paymentGateway === PaymentGatewayCore.Davinci,
  );
  const paymentTransactionTantosvago = paymentTransactions?.find(
    paymentTransaction =>
      paymentTransaction?.paymentGateway === PaymentGatewayCore.Tantosvago ||
      paymentTransaction?.paymentGateway === PaymentGatewayCore.Welbee,
  );
  if ([paymentTransactionTantosvago].some(paymentStatus => paymentStatus?.status === PaymentStatusCore.Success)) {
    return 'Pagato in crediti welfare';
  }
  if (
    [paymentTransactionStripe].some(paymentStatus => paymentStatus?.status === PaymentStatusCore.Success) ||
    (paymentTransactionDavinci?.status === PaymentStatusCore.Success && !paymentTransactionStripe?.amount)
  )
    return 'Pagamento online';
  return 'Pagamento non ancora effettuato';
};
