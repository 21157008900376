import Image from 'next/image';
import Link from 'next/link';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { sanitizeImageUrl } from '@/utils/images';

type ClinicHeaderProps = {
  logoUrl: string;
  clinicName: string;
  isLicensed: boolean;
  completeAddress: string;
  allClinicServicesLink: string;
  onShowAllServices: () => void;
};

export const ClinicHeader: React.FC<ClinicHeaderProps> = ({
  logoUrl,
  clinicName,
  isLicensed,
  completeAddress,
  allClinicServicesLink,
  onShowAllServices,
}) => (
  <div className="flex gap-3">
    {logoUrl && (
      <Image
        src={sanitizeImageUrl(logoUrl)}
        alt={`${clinicName}`}
        className="h-[70px] w-[70px] rounded-full border border-gray-200 object-contain object-center"
        width={70}
        height={70}
      />
    )}
    <div className="flex flex-col gap-y-1">
      <span className="flex items-center gap-2 text-lg font-semibold text-neutral-darker md:text-xl">
        <Link href={allClinicServicesLink} prefetch={false}>
          <h2 data-cy="card-name" className="cursor-pointer">
            {clinicName}
          </h2>
        </Link>
        {isLicensed && <CheckBadgeIcon data-cy="licensed-badge" className="size-5 shrink-0 text-primary-active lg:size-6" />}
      </span>
      <span className="text-xs font-normal text-neutral-lighter md:text-sm md:font-medium">{completeAddress}</span>
      {isLicensed && (
        <button aria-label="Vedi tutte le prestazioni" className="w-fit text-xs text-primary underline" onClick={onShowAllServices}>
          Vedi tutte le prestazioni
        </button>
      )}
    </div>
  </div>
);
