import { CLIENT_ENV } from '@/config/configuration';
import { trackNewError } from './error';
import { getCookieByName } from './cookies';

enum EventsToTrack {
  ADD_TO_CART = 'Add To Cart',
  INITIATE_CHECKOUT = 'InitiateCheckout',
  PURCHASE = 'Purchase',
}
declare const fbq: any;
export type MetaPixelProduct = {
  value: number | string;
  currency: string;
  content_ids: string;
  content_type: string;
  content_name: string;
  content_category: string;
};

// export const trackEvent = <T extends facebook.Pixel.CustomParameters>(event: string, parameters: T) => {
//   if (canTrackEvents()) {
//     return fbq('track', event, parameters);
//   }
// };

export const trackAddToCartEvent = (data: MetaPixelProduct) => {
  if (canTrackEvents()) {
    return fbq('track', EventsToTrack.ADD_TO_CART, data);
  }
};

export const trackInitiateCheckout = (data: MetaPixelProduct) => {
  if (canTrackEvents()) {
    return fbq('track', EventsToTrack.INITIATE_CHECKOUT, data);
  }
};

export const canTrackEvents = () => {
  if (typeof fbq === 'undefined') {
    console.warn('Meta PIXEL not initialized!');
  }

  return typeof fbq !== 'undefined' && CLIENT_ENV().NODE_ENV === 'production' && CLIENT_ENV().NEXT_PUBLIC_ENV === 'production';
};

const URL =
  'https://graph.facebook.com/v19.0/737221764804423/events?access_token=EAAH6lWROWDEBANuzHwXuRZBty8nDgLyaxhZCXBnIytz3sp6GH1Jj6OU8dLcpuy3JFq6VMlEBDl9vcQ2YvTA6BQntW00i8ZAD6QsmPUZCkZABTvcwPDTeVYwDMMax7dR5ZChJ3Ns9LsZBgq1HLkTHts4DujjHwpqesME37ul5OQPI5ZBnbsI9vtyby67BZCun6PCoZD';

export const trackPurchase = (userEmail: string, price: number, service: string) => {
  console.log('trackPurchase meta');
  if (CLIENT_ENV().NODE_ENV === 'production' && CLIENT_ENV().NEXT_PUBLIC_ENV === 'production') {
    const fbcValue = getCookieByName('_fbc');
    const eventData = {
      data: [
        {
          event_name: 'Purchase',
          event_time: Math.floor(Date.now() / 1000),
          user_data: {
            fbc: fbcValue ?? '',
            client_ip_address: '0.0.0.0',
            client_user_agent: userEmail,
          },
          custom_data: {
            value: price,
            currency: 'EUR',
            content_ids: [service],
            content_type: 'product',
          },
        },
      ],
    };

    fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventData),
    }).catch(error => {
      trackNewError(error);
    });
  }
};
