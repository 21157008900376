import RecapChangeSpecialization from '@/app/_components/widgets/checkoutWidget/recap/recapChangeSpecialization';
import { SlotAvailability } from '@/app/_components/widgets/listing/types';
import { WorkgroupByIdDocument } from '@/types-aggregatore';
import { useQuery } from '@apollo/client';

export const ChangeSpecializationWrapper: React.FC<{
  workgroupId: string;
  domiciliaryCare: boolean;
  onCancel: () => void;
  onNewSlot: (slot: SlotAvailability) => void;
}> = ({ workgroupId, domiciliaryCare, onCancel, onNewSlot }) => {
  const { data: ambulatory, loading: isAmbulatoryLoading } = useQuery(WorkgroupByIdDocument, {
    variables: { id: workgroupId },
    fetchPolicy: 'no-cache',
  });

  return (
    <RecapChangeSpecialization
      onNewSlot={onNewSlot}
      onCancel={onCancel}
      workgroupId={workgroupId}
      workgroupName={ambulatory?.workgroupById?.name ?? ''}
      domiciliaryCare={domiciliaryCare}
      specializations={ambulatory?.workgroupById?.specializations ?? []}
      isLoading={isAmbulatoryLoading}
    />
  );
};
