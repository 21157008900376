export const DavinciLogo = () => (
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_34158_59635)">
      <path
        d="M6.79921 8.36518C5.35017 6.90908 5.35017 4.54807 6.79921 3.09197C6.80507 3.08607 6.81094 3.08116 6.81681 3.07527C5.36679 1.63587 3.02995 1.64176 1.58678 3.09197C0.137741 4.54807 0.137741 6.90908 1.58678 8.36518L5.77452 12.5733C6.49903 13.3014 7.44844 13.6649 8.39784 13.6649C9.34724 13.6649 10.282 13.3063 11.0045 12.589C10.9987 12.5832 10.9928 12.5782 10.9869 12.5723L6.79921 8.36419V8.36518Z"
        fill="#0F285E"
      />
      <path
        d="M20.418 3.09847C18.969 1.64236 16.6194 1.64236 15.1704 3.09847L13.6108 4.66559L12.0464 3.09355C10.6033 1.64335 8.26642 1.63843 6.81641 3.07685C6.82227 3.08274 6.82814 3.08766 6.83401 3.09355L11.0217 7.3017C12.4708 8.7578 12.4708 11.1188 11.0217 12.5749C11.0159 12.5808 11.01 12.5857 11.0041 12.5916C11.7267 13.3079 12.6683 13.6675 13.6108 13.6675C14.5534 13.6675 15.5097 13.3039 16.2342 12.5759L20.417 8.37265C21.8661 6.91655 21.8661 4.55555 20.417 3.09945L20.418 3.09847Z"
        fill="#0F285E"
      />
    </g>
    <defs>
      <clipPath id="clip0_34158_59635">
        <rect width="21" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
