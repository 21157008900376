import { NoAvailabilitiesInfo } from './NoAvailabilitiesInfo';
import { getStartOfDayTime } from '@/app/_components/widgets/listing/_utils/time';
import { TimeButton } from '@/app/_components/widgets/listing/cardV2/TimeButton';
import { capitalizeWordsInString } from '@/utils/string';

const DAYS_TO_SHOW = 5;

const getDateTitle = (date: number) => {
  const todayMs = getStartOfDayTime(Date.now());
  const tomorrowMs = getStartOfDayTime(Date.now(), 1);

  // if today
  if (date === todayMs) {
    return 'Oggi';
  } else if (date === tomorrowMs) {
    return 'Domani';
  } else {
    const dateObj = new Date(date);
    return capitalizeWordsInString(dateObj.toLocaleDateString('it', { day: 'numeric', month: 'short' }));
  }
};

export const CardAvailabilities: React.FC<{
  isLoading: boolean;
  selectedDay: number | 'OTHER' | null;
  onDayClick: (date: number) => void;
  onOtherAvailabilitiesClick: () => void;
  onNoAvailabilitiesClick: () => void;
  groupedDays: { date: number; availabilityCount: number }[];
}> = ({ selectedDay, onDayClick, onOtherAvailabilitiesClick, onNoAvailabilitiesClick, isLoading, groupedDays }) => {
  const dataCy = 'card-availability';
  if (isLoading)
    return (
      <div className="grid grid-cols-3 gap-3">
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index} className="h-[52px] w-full animate-pulse rounded-lg bg-slate-300"></div>
        ))}
      </div>
    );

  if (groupedDays.length === 0)
    return (
      <NoAvailabilitiesInfo
        title="Al momento non ci sono orari disponibili"
        description="Vedi tutte le prestazioni"
        onClick={onNoAvailabilitiesClick}
      />
    );

  return (
    <div className="grid grid-cols-3 gap-3">
      {groupedDays.slice(0, DAYS_TO_SHOW).map(day => {
        const title = getDateTitle(day.date);

        return (
          <TimeButton
            key={day.date}
            dataCy={dataCy}
            title={title}
            isSelected={selectedDay === day.date}
            description={`${day.availabilityCount} orari`}
            onClick={() => {
              onDayClick(day.date);
            }}
          />
        );
      })}
      {/* Other availabilities always in position 6 */}
      <TimeButton dataCy={dataCy} title="Altri orari" isSelected={selectedDay === 'OTHER'} onClick={onOtherAvailabilitiesClick} />

      {/* Fill empty slots to ensure 2 rows */}
      {Array.from({ length: Math.max(0, 5 - groupedDays.length) }).map((_, index) => (
        <div key={`empty-${index}`} className="h-[52px] rounded-lg " />
      ))}
    </div>
  );
};
