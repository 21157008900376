import { SortingListingType } from '@/api/algolia';
import { getUserLocation } from '@/api/mapbox';
import { getGeoLocationParams } from '@/app/_components/widgets/listing';
import { useIsScreen } from '@/app/_hooks/useIsScreen';
import { useListingFilters } from '@/app/_hooks/useListingFilters';
import { ElOrderItem, ELTooltip } from '@davincihealthcare/elty-design-system-react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useSearchParams } from 'next/navigation';
import { useMemo, useState } from 'react';

type ListingOrderProps = {
  orderValues: {
    closestDistanceLabel?: string;
    cheapestInEurosLabel?: string;
    firstAvailabilityLabel?: string;
  };
  loadingOrderValues: {
    closestDistance?: boolean;
    cheapestInEuros?: boolean;
    firstAvailability?: boolean;
  };
};

export const ListingOrder = ({ orderValues, loadingOrderValues }: ListingOrderProps) => {
  const params = useSearchParams();
  const [isUserLocationLoading, setUserLocationLoading] = useState(false);

  const {
    filters: { order },
    applyFilter,
  } = useListingFilters();

  const selectOrReset = (input: SortingListingType, additionalParams?: URLSearchParams) => {
    if (order === input) {
      applyFilter('order', undefined);
    } else {
      applyFilter('order', input, additionalParams);
    }
  };

  const temporaryOrder = useMemo(() => (isUserLocationLoading ? SortingListingType.distanceAsc : order), [isUserLocationLoading, order]);

  const isTabletOrSmaller = useIsScreen('lg');
  const isMobile = useIsScreen('sm');

  const getPosition = async () => {
    setUserLocationLoading(true);
    const userLocation = await getUserLocation();
    if (!userLocation?.userCoordinates || !userLocation.userLocationName) {
      setUserLocationLoading(false);
      return;
    }
    const geoParams = getGeoLocationParams(params, {
      place: userLocation.userLocationName,
      coordinates: { lat: userLocation.userCoordinates[0], lng: userLocation.userCoordinates[1] },
    });
    selectOrReset(SortingListingType.distanceAsc, geoParams);
    setUserLocationLoading(false);
  };

  return (
    <div className="mb-4 flex max-h-[70px] flex-row items-center justify-between">
      <ElOrderItem
        selected={temporaryOrder === SortingListingType.distanceAsc}
        headline={
          isMobile ? (
            <span>
              La più
              <br />
              vicina
            </span>
          ) : (
            'La più vicina'
          )
        }
        label={orderValues.closestDistanceLabel ? orderValues.closestDistanceLabel : 'Calcola'}
        loading={isUserLocationLoading || loadingOrderValues.closestDistance}
        labelUnderlined={!orderValues.closestDistanceLabel}
        onClick={getPosition}
        className="w-full justify-center rounded-l-lg"
      />
      <div className="h-full w-[4px] bg-[#D1D7E2]" />
      <ElOrderItem
        selected={temporaryOrder === SortingListingType.priceAsc}
        headline="La più economica"
        label={orderValues.cheapestInEurosLabel ? orderValues.cheapestInEurosLabel : 'Calcola'}
        labelUnderlined={!orderValues.cheapestInEurosLabel}
        loading={loadingOrderValues.cheapestInEuros}
        onClick={() => selectOrReset(SortingListingType.priceAsc)}
        className="w-full justify-center"
      />
      <div className="h-full w-[4px] bg-[#D1D7E2]" />
      <ElOrderItem
        selected={temporaryOrder === SortingListingType.firstAvailabilityAsc}
        headline={
          isTabletOrSmaller ? (
            'Prossime disponibili'
          ) : (
            <>
              <span>Prossime disponibili</span>
              <ELTooltip
                text="L’ordinamento per disponibilità è indicativo e può variare in base alle prenotazioni in tempo reale"
                className="w-48 text-black"
              >
                <InformationCircleIcon className="h-4 w-4" />
              </ELTooltip>
            </>
          )
        }
        label={orderValues.firstAvailabilityLabel ? orderValues.firstAvailabilityLabel : 'Calcola'}
        labelUnderlined={!orderValues.firstAvailabilityLabel}
        loading={loadingOrderValues.firstAvailability}
        onClick={() => selectOrReset(SortingListingType.firstAvailabilityAsc)}
        className="w-full justify-center rounded-r-lg"
      />
    </div>
  );
};
