import { macroSpecialities } from '@davincihealthcare/booking-synonyms';

export const getMacroSpeciality = (value?: string) => {
  if (!value) {
    return null;
  }

  return Object.keys(macroSpecialities).find(
    key =>
      macroSpecialities[key]?.label?.toLowerCase() === value?.toLowerCase() ||
      macroSpecialities[key]?.synonyms?.find((syn: string) => {
        return syn.toLowerCase() === value?.toLowerCase();
      }),
  );
};
