'use client';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx/lite';
import { useSearchParams } from 'next/navigation';

interface Props {
  totalPages?: number;
}

const Paginator = ({ totalPages }: Props) => {
  const params = useSearchParams();

  const parsedPage = Number(params?.get('page') ?? '1');
  const currentPage = isNaN(parsedPage) ? 1 : parsedPage;
  const updateQueryString = (nextPage?: number) => {
    const newParams = new URLSearchParams(params?.toString());
    if (!nextPage) {
      newParams.delete('page');
    } else {
      newParams.set('page', nextPage.toString());
    }

    window.history.pushState(null, '', `?${newParams.toString()}`);
  };

  const loadPage = (nextPage: number) => {
    updateQueryString(nextPage);
  };

  const loadNextPage = (current: number) => {
    updateQueryString(current + 1);
  };

  const loadPreviousPage = (current: number) => {
    updateQueryString(current - 1);
  };

  const isVisible = (current: number, page: number): boolean => {
    const maxPagesToShow = 5;
    const minPageVisible = page - Math.floor(maxPagesToShow / 2);
    const maxPageVisible = page + Math.floor(maxPagesToShow / 2);
    if (
      (current < minPageVisible && page - maxPagesToShow >= 1) ||
      (current > maxPageVisible && page + maxPagesToShow <= (totalPages ?? 0))
    ) {
      return false;
    }
    return true;
  };
  if (!totalPages || totalPages <= 1) {
    return null;
  }
  if (currentPage > totalPages) {
    updateQueryString(0);
    return null;
  }

  return (
    <div className="flex items-center justify-center" data-cy="paginator">
      <div className="bg-primary-300 flex h-12 min-w-20 max-w-96 items-center justify-center gap-x-3 rounded-full p-2 md:h-14 md:p-5">
        <button aria-label="Precedente" onClick={() => loadPreviousPage(currentPage)} disabled={currentPage === 1} data-cy="previous">
          <ChevronLeftIcon className={clsx('h-4 w-4', currentPage === 1 ? 'text-neutral-inactive' : 'text-primary')} />
        </button>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
          <button
            aria-label={`Pagina ${page}`}
            key={page}
            onClick={() => loadPage(page)}
            data-cy={`paginator-${page}`}
            className={clsx(
              'mx-1 h-7 w-7 rounded-full text-center text-base font-semibold hover:bg-primary-light-hover hover:text-primary-hover active:bg-primary-light-active active:text-primary-active md:h-10 md:w-10',
              currentPage === page ? 'selected border border-primary-active bg-primary-light-active text-primary-active' : 'text-primary',
              isVisible(currentPage, page) ? 'block' : 'hidden',
            )}
          >
            {page}
          </button>
        ))}
        <button aria-label="Successivo" onClick={() => loadNextPage(currentPage)} disabled={currentPage === totalPages} data-cy="next">
          <ChevronRightIcon className={clsx('h-4 w-4', currentPage === totalPages ? 'text-neutral-inactive' : 'text-primary')} />
        </button>
      </div>
    </div>
  );
};

export default Paginator;
