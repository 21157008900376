import { ELButton, ELCheckbox } from '@davincihealthcare/elty-design-system-react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { useState } from 'react';

export type SelectCheckBoxProps<T> = {
  label: string;
  options: {
    value: T | undefined;
    label: string;
  }[];
  selected: T[] | undefined;
  setSelected: (filter: T[] | undefined) => void;
} & (
  | {
      primaryCTA: {
        label: string;
        onClick: () => void;
      };
      secondaryCTA: {
        label: string;
        onClick: () => void;
      };
      floating: boolean;
    }
  | { floating?: undefined; primaryCTA?: undefined; secondaryCTA?: undefined }
);

const SelectCheckBox = <T extends string | undefined>({
  label,
  options,
  selected,
  setSelected,
  primaryCTA,
  secondaryCTA,
  floating,
}: SelectCheckBoxProps<T>) => {
  const [isFiltersCollapsed, setFiltersCollapsed] = useState(false);

  return (
    <div
      className={clsx(
        floating ? 'absolute w-[365px] shadow-xl' : 'relative w-full',
        'z-10 mt-1 flex flex-col gap-3 bg-neutral-surface p-3',
      )}
    >
      <div className="flex items-center justify-between">
        <h1 className="py-1 text-base font-semibold text-primary">{label}</h1>
        {!floating && (
          <ChevronUpIcon
            className={clsx(isFiltersCollapsed && 'rotate-180', 'w-4 cursor-pointer')}
            onClick={() => setFiltersCollapsed(prevValue => !prevValue)}
          />
        )}
      </div>
      <div className={clsx(isFiltersCollapsed && 'hidden h-0', 'flex flex-col gap-3')}>
        {options.map((option, index) => (
          <React.Fragment key={option.label}>
            <ELCheckbox
              name={`checkbox_button_${index}`}
              aria-label={option.label}
              label={option.label}
              onChange={() => {
                if (option.value === undefined) {
                  setSelected(undefined);
                } else {
                  if (!selected?.some(selecteVal => selecteVal === option.value)) {
                    setSelected([...(selected ?? []), option.value]);
                  } else {
                    setSelected([...(selected?.filter(selectedValue => selectedValue !== option.value) || [])]);
                  }
                }
              }}
              checked={selected?.length ? selected?.some(selecteVal => selecteVal === option.value) : option?.value === undefined}
            />
            {index !== options.length - 1 && <hr />}
          </React.Fragment>
        ))}
      </div>
      {floating ? (
        <div className="flex flex-row items-center justify-end gap-3">
          <ELButton
            aria-label={'button'}
            size="small"
            variant="text"
            color="primary"
            label={secondaryCTA.label}
            onClick={secondaryCTA.onClick}
          />
          <ELButton
            aria-label={'button'}
            size="small"
            variant="filled"
            color="primary"
            label={primaryCTA.label}
            onClick={primaryCTA.onClick}
          />
        </div>
      ) : (
        <hr className="w-full" />
      )}
    </div>
  );
};

export default SelectCheckBox;
