import Modal from '@/app/_components/modal';
import SelectCheckBox from '@/app/_components/widgets/listing/listingFilters/selectCheckbox';
import SelectRadioButton from '@/app/_components/widgets/listing/listingFilters/selectRadioButton';
import { dateOptions, locationOptions, paymentOptions } from '@/app/_components/widgets/listing/listingFilters/utils';
import { useDisableBodyScroll } from '@/app/_hooks/useDisableBodyScroll';
import { useIsTantosvago } from '@/app/_hooks/useIsTantosvago';
import { FilterStates, useListingFilters } from '@/app/_hooks/useListingFilters';
import { ELButton, ELTagButton } from '@davincihealthcare/elty-design-system-react';
import { AdjustmentsHorizontalIcon, MapPinIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

type FiltersFormModalProps = {
  onClose: () => void;
};

const FiltersFormModal = ({ onClose }: FiltersFormModalProps) => {
  const { filters, applyFilters, clearFilters } = useListingFilters();
  const [modalFilters, setModalFilters] = useState(filters);

  const isWelfare = useIsTantosvago();

  const setModalFilter =
    <T extends keyof FilterStates>(filter: T) =>
    (value?: FilterStates[T]) => {
      const newFilters = { ...modalFilters, [filter]: value };
      setModalFilters(newFilters);
    };

  return (
    <Modal
      className="absolute mb-0 ml-auto flex h-[90%] w-[100vw] max-w-[100vw] flex-col items-center justify-between gap-4 rounded-t-xl px-3 py-5"
      onClose={onClose}
      isModalOpen
    >
      <div className="flex w-full flex-col items-center justify-start">
        <div className="flex w-full items-center justify-between p-2">
          <div />
          <h1 className="text-base font-semibold text-primary">Filtri</h1>
          <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={onClose} />
        </div>
        <div className="flex max-h-full w-full flex-col items-center justify-start overflow-y-auto">
          <SelectRadioButton
            label={dateOptions.title}
            options={[...dateOptions.options, { label: dateOptions.defaultOptionLabel, value: undefined }]}
            selected={modalFilters.dateFilter}
            setSelected={setModalFilter('dateFilter')}
          />
          {!isWelfare && (
            <SelectRadioButton
              label={paymentOptions.title}
              options={[...paymentOptions.options, { label: paymentOptions.defaultOptionLabel, value: undefined }]}
              selected={modalFilters.paymentFilter}
              setSelected={setModalFilter('paymentFilter')}
            />
          )}
          <SelectCheckBox
            label={locationOptions.title}
            options={[...locationOptions.options, { label: locationOptions.defaultOptionLabel, value: undefined }]}
            selected={modalFilters.channelFilter}
            setSelected={setModalFilter('channelFilter')}
          />
        </div>
      </div>
      <div className="flex w-full flex-col items-center justify-end gap-5 py-5">
        <ELButton
          aria-label={'button'}
          size="large"
          fullWidth
          variant="filled"
          color="primary"
          label="Applica tutti i filtri"
          onClick={() => {
            applyFilters(modalFilters);
            onClose();
          }}
        />
        <ELButton
          aria-label={'button'}
          size="large"
          fullWidth
          variant="text"
          color="primary"
          label="Annulla tutti i filtri"
          onClick={() => {
            clearFilters();
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};

type FiltersFormType = {
  showMap: () => void;
  filterLight?: boolean;
};

const FiltersForm = ({ showMap }: FiltersFormType) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  useDisableBodyScroll(isModalOpen);

  const { filters } = useListingFilters();

  return (
    <div className="h-13 bg-neutral-active px-4 py-2 ">
      <div className="container mx-auto flex w-full flex-row items-center justify-between">
        <ELTagButton
          leadingIcon={<AdjustmentsHorizontalIcon />}
          label="Filtri"
          onClick={() => setIsModalOpen(true)}
          state={
            Object.keys(filters)
              .filter(key => key !== 'order')
              .some(filterKey => !!filters[filterKey as keyof FilterStates]?.length)
              ? 'active'
              : undefined
          }
        />
        <ELTagButton leadingIcon={<MapPinIcon />} label="Mappa" onClick={showMap} />
      </div>
      {isModalOpen && <FiltersFormModal onClose={() => setIsModalOpen(false)} />}
    </div>
  );
};

export default FiltersForm;
