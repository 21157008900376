import { ELButton, ELLink } from '@davincihealthcare/elty-design-system-react';

type CommonProps = {
  title: string;
  description: string;
};

type NoAvailabilitiesInfoProps = CommonProps & ({ onClick: () => void; path?: never } | { path: string; onClick?: never });

export const NoAvailabilitiesInfo: React.FC<NoAvailabilitiesInfoProps> = ({ path, title, description, onClick }) => (
  <div className="flex h-full flex-col items-center justify-center gap-5 text-center">
    <p className="text-base font-medium text-neutral-lighter">{title}</p>
    {onClick && <ELButton aria-label={description} size="small" variant="outlined" color="primary" label={description} onClick={onClick} />}
    {path && <ELLink aria-label={description} size="small" variant="outlined" color="primary" label={description} href={path} />}
  </div>
);
