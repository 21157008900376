import { useCallback, useState } from 'react';

const topRootMargin = '150px';

export const useInView = () => {
  const [isInView, setIsInView] = useState(false);
  const ref = useCallback((node: HTMLElement | null) => {
    if (!node) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0, rootMargin: topRootMargin },
    );

    observer.observe(node);
    return () => observer.disconnect();
  }, []);

  return { isInView, ref };
};
