import { useIsScreen } from '@/app/_hooks/useIsScreen';
import FiltersForm from '@/app/_components/widgets/listing/listingFilters/filtersForm';
import FilterButtons from '@/app/_components/widgets/listing/listingFilters/filterButtons';

type ListingFiltersProps = {
  showMap: () => void;
  filterLight?: boolean;
};

const ListingFilters = ({ filterLight, showMap }: ListingFiltersProps) => {
  const isTablet = useIsScreen('lg');

  if (isTablet) {
    return <FiltersForm showMap={showMap} filterLight={filterLight} />;
  }

  return <FilterButtons filterLight={filterLight} />;
};

export default ListingFilters;
