import { ChannelFilterType, DateFilterType, PaymentFilterType } from '@/api/algolia';

export const dateOptions = {
  title: 'Per quando vorresti prenotare?',
  defaultOptionLabel: 'Qualsiasi data',
  options: [
    {
      label: 'Entro 3 giorni',
      value: DateFilterType.three_days,
    },
    {
      label: 'Entro 7 giorni',
      value: DateFilterType.seven_days,
    },
  ],
};

export const paymentOptions = {
  title: 'Pagamento in sede ed online',
  defaultOptionLabel: 'Vedi tutti i metodi di pagamento',
  options: [
    {
      label: 'Solo pagamento in struttura',
      value: PaymentFilterType.onsite,
    },
    {
      label: 'Solo pagamento online',
      value: PaymentFilterType.online,
    },
  ],
};

export const locationOptions = {
  title: 'Quale tipo di prestazioni vorresti prenotare?',
  defaultOptionLabel: 'Vedi tutte',
  options: [
    {
      label: 'Online',
      value: ChannelFilterType.online,
    },
    {
      label: 'A domicilio',
      value: ChannelFilterType.domiciliary,
    },
    {
      label: 'In sede',
      value: ChannelFilterType.onsite,
    },
    // {
    //   label: 'Prestazioni infermieristiche',
    //   value: ChannelFilterType.domiciliary,
    // },
  ],
};
