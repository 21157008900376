import { ChannelFilterType, DateFilterType, PaymentFilterType, SortingListingType } from '@/api/algolia';
import { getEnumValueFromString } from '@/utils/string';
import { useSearchParams } from 'next/navigation';

export type FilterStates = {
  dateFilter: DateFilterType | undefined;
  paymentFilter: PaymentFilterType | undefined;
  channelFilter: ChannelFilterType[] | undefined;
  order: SortingListingType | undefined;
};

export const useListingFilters = () => {
  const searchParams = useSearchParams();

  const filterStates = {
    dateFilter: searchParams?.get('dateFilter') || undefined,
    paymentFilter: searchParams?.get('paymentFilter') || undefined,
    channelFilter: searchParams?.get('channelFilter') ? searchParams?.get('channelFilter')!.split(',') : [],
    order: getEnumValueFromString(SortingListingType, searchParams?.get('order') || ''),
  } as Partial<FilterStates>;

  const applyFilter = <T extends keyof FilterStates>(filterKey: T, newFilter?: FilterStates[T], additionalParams?: URLSearchParams) => {
    const newParams = new URLSearchParams((additionalParams || searchParams)?.toString());
    newParams.delete(filterKey);

    if (newFilter) {
      newParams.set(filterKey, newFilter.toString());
    }

    window.history.pushState(null, '', `?${newParams.toString()}`);
  };

  const applyFilters = (newFilters: Partial<FilterStates>) => {
    const newParams = new URLSearchParams(searchParams?.toString());

    Object.keys(newFilters).forEach(filterKey => {
      newParams.delete(filterKey);

      const filterValue = newFilters[filterKey as keyof FilterStates];
      if (filterValue) newParams.set(filterKey, filterValue.toString());
    });

    window.history.pushState(null, '', `?${newParams.toString()}`);
  };

  const clearFilters = () => {
    const newParams = new URLSearchParams(searchParams?.toString());

    newParams.delete('dateFilter');
    newParams.delete('paymentFilter');
    newParams.delete('channelFilter');

    window.history.pushState(null, '', `?${newParams.toString()}`);
  };

  return {
    filters: filterStates,
    applyFilter,
    applyFilters,
    clearFilters,
  };
};
