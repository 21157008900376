import { ELTagButton } from '@davincihealthcare/elty-design-system-react';
import { useRef, useState } from 'react';
import { useOutsideClick } from '@/app/_hooks/useOutsideClick';
import { FilterStates, useListingFilters } from '@/app/_hooks/useListingFilters';
import Container from '@/app/_components/container';
import SelectRadioButton from './selectRadioButton';
import { CalendarIcon, CreditCardIcon, HomeIcon } from '@heroicons/react/24/outline';
import { dateOptions, locationOptions, paymentOptions } from '@/app/_components/widgets/listing/listingFilters/utils';
import SelectCheckBox from '@/app/_components/widgets/listing/listingFilters/selectCheckbox';
import { useIsTantosvago } from '@/app/_hooks/useIsTantosvago';

type FilterButtonProps<T> = {
  filterLight?: boolean;
};

const FilterButtons = <T extends keyof FilterStates>({}: FilterButtonProps<T>) => {
  const { filters, applyFilter } = useListingFilters();
  const [modalFilters, setModalFilters] = useState(filters);

  const isWelfare = useIsTantosvago();

  const getFilterCallback =
    <T extends keyof FilterStates>(filter: T) =>
    (newValue: FilterStates[T]) => {
      setModalFilters(currentVal => ({
        ...currentVal,
        [filter]: newValue,
      }));
    };

  const [openModal, setOpenModal] = useState<keyof Partial<FilterStates>>();
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClick(wrapperRef, () => setOpenModal(undefined));

  return (
    <Container className={`hidden bg-neutral-active md:block`}>
      <div className="grid h-14 grid-rows-1 items-center">
        <div className="hidden md:flex md:items-center md:gap-x-3" data-cy="search-result-filter-bar" ref={wrapperRef}>
          <span className="text-sm font-medium text-neutral-darker">Perfeziona la tua ricerca</span>
          <div className="relative">
            <ELTagButton
              label="Date disponibili"
              leadingIcon={<CalendarIcon />}
              state={(openModal === 'dateFilter' && 'focused') || (filters.dateFilter && 'active') || undefined}
              onClick={() => {
                if (openModal === 'dateFilter') {
                  setOpenModal(undefined);
                } else {
                  setOpenModal('dateFilter');
                }
              }}
            />
            {openModal === 'dateFilter' && (
              <SelectRadioButton
                label={dateOptions.title}
                options={[...dateOptions.options, { label: dateOptions.defaultOptionLabel, value: undefined }]}
                selected={modalFilters.dateFilter}
                setSelected={getFilterCallback('dateFilter')}
                primaryCTA={{
                  label: 'Applica',
                  onClick: () => {
                    applyFilter('dateFilter', modalFilters.dateFilter);

                    setOpenModal(undefined);
                  },
                }}
                secondaryCTA={{
                  label: 'Annulla questo filtro',
                  onClick: () => {
                    getFilterCallback('dateFilter')(undefined);
                    applyFilter('dateFilter', undefined);
                    setOpenModal(undefined);
                  },
                }}
                floating
              />
            )}
          </div>
          {!isWelfare && (
            <div className="relative">
              <ELTagButton
                label="Pagamento"
                leadingIcon={<CreditCardIcon />}
                state={(openModal === 'paymentFilter' && 'focused') || (filters.paymentFilter && 'active') || undefined}
                onClick={() => {
                  if (openModal === 'paymentFilter') {
                    setOpenModal(undefined);
                  } else {
                    setOpenModal('paymentFilter');
                  }
                }}
              />
              {openModal === 'paymentFilter' && (
                <SelectRadioButton
                  label={paymentOptions.title}
                  options={[...paymentOptions.options, { label: paymentOptions.defaultOptionLabel, value: undefined }]}
                  selected={modalFilters.paymentFilter}
                  setSelected={getFilterCallback('paymentFilter')}
                  primaryCTA={{
                    label: 'Applica',
                    onClick: () => {
                      applyFilter('paymentFilter', modalFilters.paymentFilter);
                      setOpenModal(undefined);
                    },
                  }}
                  secondaryCTA={{
                    label: 'Annulla questo filtro',
                    onClick: () => {
                      getFilterCallback('paymentFilter')(undefined);
                      applyFilter('paymentFilter', undefined);
                      setOpenModal(undefined);
                    },
                  }}
                  floating
                />
              )}
            </div>
          )}
          <div className="relative">
            <ELTagButton
              label="Domicilio, online o in sede"
              leadingIcon={<HomeIcon />}
              state={(openModal === 'channelFilter' && 'focused') || (filters.channelFilter?.length && 'active') || undefined}
              onClick={() => {
                if (openModal === 'channelFilter') {
                  setOpenModal(undefined);
                } else {
                  setOpenModal('channelFilter');
                }
              }}
            />
            {openModal === 'channelFilter' && (
              <SelectCheckBox
                label={locationOptions.title}
                options={[...locationOptions.options, { label: locationOptions.defaultOptionLabel, value: undefined }]}
                selected={modalFilters.channelFilter}
                setSelected={getFilterCallback('channelFilter')}
                primaryCTA={{
                  label: 'Applica',
                  onClick: () => {
                    applyFilter('channelFilter', modalFilters.channelFilter);
                    setOpenModal(undefined);
                  },
                }}
                secondaryCTA={{
                  label: 'Annulla questo filtro',
                  onClick: () => {
                    getFilterCallback('channelFilter')(undefined);
                    applyFilter('channelFilter', []);
                    setOpenModal(undefined);
                  },
                }}
                floating
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FilterButtons;
